import React from 'react';
import Hero from '../components/Hero';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../styles/Home.css';
import testimonial1 from '../assets/images/lifuti.jpeg';
import testimonial2 from '../assets/images/gift.jpeg';
import testimonial3 from '../assets/images/bkg.jpg';
import { FaCar, FaWrench, FaPaintBrush, FaBus } from 'react-icons/fa';
import FeaturedCard from '../components/FeaturedCard';

const Home = () => {
  const featuredDeals = [
    {
      image: testimonial1,
      title: 'Amazing Car Deal 1',
      description: 'Get this amazing car at a great price!',
      date: '2024-07-01',
    },
    {
      image: testimonial2,
      title: 'Amazing Car Deal 2',
      description: 'Another great car deal for you!',
      date: '2024-07-05',
    },
    {
      image: testimonial3,
      title: 'Amazing Car Deal 3',
      description: 'This deal won’t last long!',
      date: '2024-07-10',
    },
  ];
  const services = [
    {
      icon: <FaCar />,
      title: 'Car Sales',
      description:
        'Looking to upgrade your ride or find a great deal? We offer a seamless platform to buy and resell second-hand cars. Browse our listings or sell your car with ease.',
      link: '/services/buy-and-sell-cars', // Replace with actual service page link
    },
    {
      icon: <FaWrench />,
      title: 'Car Repairs',
      description:
        'Need expert repairs or maintenance for your car? Our team of certified mechanics will get your car running smoothly again. Schedule an appointment today!',
      link: '/services/car-repairs', // Replace with actual service page link
    },
    {
      icon: <FaPaintBrush />,
      title: 'Car Upgrades',
      description:
        'Give your car a makeover! We offer a variety of services from interior detailing to exterior upgrades. Personalize your ride and make it stand out.',
      link: '/services/car-decoration-upgrades', // Replace with actual service page link
    },
    {
      icon: <FaBus />,
      title: 'Transportation',
      description:
        'Need a reliable and comfortable ride? We offer a range of transportation services to suit your needs. Choose from car rentals, taxi services, or chauffeur-driven options.',
      link: '/services/transportation', // Replace with actual service page link
    },
  ];

  const testimonials = [
    {
      image: testimonial1,
      name: 'Anton',
      title: 'Developer',
      quote:
        'By adding this check, the error should no longer occur when the modal is opened or closed, ensuring a smoother user experience. Adjust any other functionality or styling as needed based on your specific design requirements.',
    },
    {
      image: testimonial2,
      name: 'Anton',
      title: 'Developer',
      quote:
        'By adding this check, the error should no longer occur when the modal is opened or closed, ensuring a smoother user experience. Adjust any other functionality or styling as needed based on your specific design requirements.',
    },
    //
    // Add more testimonials here
  ];

  const ServiceItem = ({ icon, title, description }) => (
    <div className='service-item'>
      <i>{icon}</i>
      <h2>{title}</h2>
      <p>{description}</p>
      <button>Read More</button>
    </div>
  );

  const TestimonialItem = ({ image, name, title, quote }) => (
    <div className='testimonial-item'>
      <div className='person'>
        <div className='container'>
          <div className='container-inner'>
            <img className='circle' src={image} alt={name} />
          </div>
        </div>
        <div className='divider'></div>
        <div className='name'>{name}</div>
        <div className='title'>{title}</div>
      </div>
      <div className='testimony'>
        <p>{quote}</p>
      </div>
    </div>
  );

  return (
    <div>
      <Hero />
      <section id='featured-church' className='featured-church'>
        <h2>Featured Car Deals</h2>
        <div className='featured-cards'>
          {featuredDeals.map((deal, index) => (
            <FeaturedCard
              key={index}
              image={deal.image}
              title={deal.title}
              description={deal.description}
              date={deal.date}
            />
          ))}
        </div>
      </section>
      <section id='services' className='services'>
        <h2>Our Services</h2>
        <div className='service-list'>
          {services.map((service) => (
            <ServiceItem
              key={service.title}
              icon={service.icon}
              title={service.title}
              description={service.description}
              link={service.link}
            />
          ))}
        </div>
      </section>
      <section id='gallery' className='gallery'>
        <h2>What Our Customers Say</h2>
        <Carousel
          className='carousel-container'
          showArrows={true}
          autoPlay={true}
          infiniteLoop={true}>
          {testimonials.map((testimonial, index) => (
            <TestimonialItem
              key={index}
              image={testimonial.image}
              name={testimonial.name}
              title={testimonial.title}
              quote={testimonial.quote}
            />
          ))}
        </Carousel>
      </section>
      <section id='about-us' className='about-us'>
        <h2>About Us</h2>
        <div className='aboutusmain-container'>
          <div className='aboutusmain-container__lefttside'>
            <img src={testimonial3} alt='about' />
          </div>
          <div className='aboutusmain-container__rightside'>
            {/* Description */}
            <p>
              JMASATI Car Services & Transportation CC provides a wide range of
              services including car sales, repairs, upgrades, and
              transportation solutions.
            </p>
            {/* Mission */}
            <h2>Mission</h2>
            <p>
              Our mission is to offer top-notch car services and transportation
              solutions that exceed customer expectations, ensuring safety,
              reliability, and value.
            </p>
            {/* Vision */}
            <h2>Vision</h2>
            <p>
              Our vision is to be a leading provider of car services and
              transportation solutions, recognized for our commitment to
              quality, innovation, and customer satisfaction.
            </p>
            {/* Objective */}
            <h2>Objective</h2>
            <p>
              Our objective is to continuously improve our services, expand our
              offerings, and maintain a customer-centric approach in all our
              business operations.
            </p>
          </div>
        </div>
      </section>

      <section id='contact-us' className='contact-us'>
        <h2>Contact Us</h2>
        <div className='contact-us__container'>
          <div className='contact-info'>
            <p>Phone: +264 81 274 1323</p>
            <p>Email: info@jmasati.com</p>
          </div>
          <form className='contact-form'>
            <input type='text' name='name' placeholder='Name' required />
            <input type='email' name='email' placeholder='Email' required />
            <input type='tel' name='phone' placeholder='Phone' required />
            <textarea name='message' placeholder='Message' required></textarea>
            <button type='submit'>Submit</button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Home;
