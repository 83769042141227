import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <header className='header'>
        <div className='logo'>
          <h3>JMASATI</h3>
        </div>
        <nav>
          <button className='menu-toggle' onClick={toggleMenu}>
            ☰
          </button>
          <ul className={menuOpen ? 'open' : ''}>
            <li>
              <Link to='/' onClick={closeMenu}>
                Home
              </Link>
            </li>
            <li>
              <Link to='/services' onClick={closeMenu}>
                Services
              </Link>
            </li>
            <li>
              <Link to='/about' onClick={closeMenu}>
                About Us
              </Link>
            </li>
            <li>
              <Link to='/contact' onClick={closeMenu}>
                Contact Us
              </Link>
            </li>
            <li>
              <Link to='/gallery' onClick={closeMenu}>
                Gallery
              </Link>
            </li>
            <li>
              <Link to='#book-now' className='book-now' onClick={closeMenu}>
                Book Now
              </Link>
            </li>
          </ul>
        </nav>
      </header>
      {menuOpen && <div className='backdrop' onClick={closeMenu}></div>}
    </>
  );
};

export default Header;
