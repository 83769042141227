import React from 'react';
import '../styles/Services.css';
import { FaCar, FaTools, FaPaintBrush, FaTruck } from 'react-icons/fa';

const Services = () => {
  return (
    <section id='services' className='services'>
      <h2>Our Services</h2>
      <div className='service-list'>
        <div className='service-item'>
          <FaCar />
          <p>Buy and Resell second-hand cars.</p>
        </div>
        <div className='service-item'>
          <FaTools />
          <p>Car Repairs.</p>
        </div>
        <div className='service-item'>
          <FaPaintBrush />
          <p>Car Decoration / Upgrades.</p>
        </div>
        <div className='service-item'>
          <FaTruck />
          <p>Provide Transportation.</p>
        </div>
      </div>
    </section>
  );
};

export default Services;
