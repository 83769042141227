import React from 'react';
import '../styles/AboutUs.css';

const AboutUs = () => {
  return (
    <section id='about-us' className='about-us'>
      <h2>About Us</h2>
      <p>
        JMASATI Car Services & Transportation CC is dedicated to providing
        exceptional services in buying and reselling second-hand cars, car
        repairs, decoration, upgrades, and transportation. Our experienced team
        ensures top-quality service and customer satisfaction.
      </p>
    </section>
  );
};

export default AboutUs;
