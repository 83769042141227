import React, { useState } from 'react';
import '../styles/ContactUs.css';

const ContactUs = () => {
  const [form, setForm] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log(form);
  };

  return (
    <section id='contact-us' className='contact-us'>
      <h2>Contact Us</h2>
      <div className='contact-info'>
        <p>Phone: +264 81 274 1323</p>
        <p>Email: info@jmasati.com</p>
      </div>
      <form className='contact-form' onSubmit={handleSubmit}>
        <input
          type='text'
          name='name'
          placeholder='Name'
          value={form.name}
          onChange={handleChange}
          required
        />
        <input
          type='email'
          name='email'
          placeholder='Email'
          value={form.email}
          onChange={handleChange}
          required
        />
        <input
          type='text'
          name='phone'
          placeholder='Phone'
          value={form.phone}
          onChange={handleChange}
          required
        />
        <textarea
          name='message'
          placeholder='Message'
          value={form.message}
          onChange={handleChange}
          required
        />
        <button type='submit'>Submit</button>
      </form>
    </section>
  );
};

export default ContactUs;
