import React, { useState } from 'react';
import '../styles/Gallery.css';
import galleryImage1 from '../assets/images/image3.jpg'; // Replace with your image paths
import galleryImage2 from '../assets/images/imag1.jpg';
import galleryImage3 from '../assets/images/image2.jpg';
import Hero from '../components/Hero';
import Modal from 'react-modal';

const carParts = [
  {
    image: galleryImage1,
    partName: 'Engine',
    carName: 'Toyota',
    model: 'Corolla 2021',
    price: '$500',
  },
  {
    image: galleryImage2,
    partName: 'Brake Pads',
    carName: 'Honda',
    model: 'Civic 2020',
    price: '$150',
  },
  {
    image: galleryImage3,
    partName: 'Headlight',
    carName: 'Ford',
    model: 'Focus 2019',
    price: '$200',
  },
  // Add more car parts as needed
];

const Gallery = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedPart, setSelectedPart] = useState(null);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userCell, setUserCell] = useState('');

  const openModal = (part) => {
    setSelectedPart(part);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedPart(null);
    setUserName('');
    setUserEmail('');
    setUserCell('');
  };

  const handleOrder = () => {
    // Handle order logic here
    alert(`Order placed for ${selectedPart.partName} by ${userName}`);
    closeModal();
  };

  const handleReservation = () => {
    // Handle reservation logic here
    alert(`Reservation made for ${selectedPart.partName} by ${userName}`);
    closeModal();
  };

  const handleEnquiry = () => {
    // Handle enquiry logic here
    alert(`Enquiry made for ${selectedPart.partName} by ${userName}`);
    closeModal();
  };

  return (
    <>
      <Hero />
      <section id='gallery' className='gallery'>
        <h2>What is in our stock</h2>
        <div className='gallery-grid'>
          {carParts.map((part, index) => (
            <div className='gallery-card' key={index}>
              <div className='image-container'>
                <img src={part.image} alt={`${part.partName} ${index + 1}`} />
                <div className='overlayGallery'>
                  <div className='overlayGallery-text'>{part.partName}</div>
                </div>
              </div>
              <div className='card-details'>
                <div className='card-details-card1'>
                  <h3>{part.carName}</h3>
                  <p>{part.model}</p>
                  <p>{part.price}</p>
                </div>
                <div className='card-details-card2'>
                  <button
                    className='order-button'
                    onClick={() => openModal(part)}>
                    Order
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel='Order Modal'
        className='modal'
        overlayClassName='modal-overlayGallery'>
        <h2>Order {selectedPart && selectedPart.partName}</h2>
        <form className='order-form'>
          <label>
            Name (Required):
            <input
              type='text'
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              required
            />
          </label>
          <label>
            Email:
            <input
              type='email'
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
            />
          </label>
          <label>
            Cell Number:
            <input
              type='tel'
              value={userCell}
              onChange={(e) => setUserCell(e.target.value)}
            />
          </label>
          <button type='button' onClick={handleOrder}>
            Order Now
          </button>
          <button type='button' onClick={handleReservation}>
            Reserve for Later
          </button>
          <button type='button' onClick={handleEnquiry}>
            Enquire
          </button>
          <button type='button' onClick={closeModal}>
            Close
          </button>
        </form>
      </Modal>
    </>
  );
};

export default Gallery;
