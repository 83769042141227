import React from 'react';
import '../styles/Footer.css';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='quick-links'>
        <a href='#home'>Home</a>
        <a href='#services'>Services</a>
        <a href='#about-us'>About Us</a>
        <a href='#contact-us'>Contact Us</a>
      </div>
      <div className='contact-info'>
        <p>Phone: +264 81 274 1323</p>
        <p>Email: jnyumbu3@gmail.com</p>
      </div>
      <div className='social-media'>
        {/* Add your social media icons with links here */}
      </div>
    </footer>
  );
};

export default Footer;
