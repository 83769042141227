// FeaturedCard.js
import React from 'react';
import PropTypes from 'prop-types';
import '../styles/Featured.css';
const FeaturedCard = ({ image, title, description, date }) => (
  <div className='featured-card'>
    <img src={image} alt={title} className='featured-card__image' />
    <div className='featured-card__content'>
      <h3 className='featured-card__title'>{title}</h3>
      <p className='featured-card__description'>{description}</p>
      <p className='featured-card__date'>{date}</p>
    </div>
  </div>
);

FeaturedCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default FeaturedCard;
