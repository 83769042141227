import React from 'react';
import '../styles/Hero.css';
import heroImage from '../assets/images/bkg.jpg'; // Replace with your image path

const Hero = () => {
  return (
    <section className='hero' style={{ backgroundImage: `url(${heroImage})` }}>
      <div className='overlay'>
        <h1>JMASATI CAR SERVICES & Transportation CC</h1>
        <p>Don't stress!! We will sell it for you.</p>
        <a href='#book-now' className='cta-button'>
          BOOK NOW
        </a>
      </div>
    </section>
  );
};

export default Hero;
